import React, { SVGProps } from 'react';

const FeedbackHomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={280}
    height={144}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#FF971D" d="M0 0h280v144H0z" />
      <path
        d="M65.503-14.62c-10.286 0-18.64 8.435-18.64 18.747V57.37c0 10.313 8.363 18.74 18.64 18.74h1.342v26.34c0 1.478.889 2.802 2.25 3.365a3.64 3.64 0 0 0 3.963-.817l28.653-28.88h23.482v29.842c0 10.312 8.363 18.775 18.639 18.775h58.593l28.681 28.881.009-.01a3.637 3.637 0 0 0 3.955.781 3.63 3.63 0 0 0 2.231-3.347v-26.304h1.342c10.286 0 18.676-8.453 18.676-18.775V52.746c0-10.313-8.39-18.775-18.676-18.775h-59.691V4.138c0-10.313-8.362-18.748-18.639-18.748l-94.81-.01Zm0 7.264h94.801c6.358 0 11.41 5.035 11.41 11.483V33.96h-27.872c-10.286 0-18.64 8.462-18.64 18.775v16.118h-24.989l-.009.01c-.97-.01-1.904.38-2.594 1.07L74.1 93.604V72.508a3.64 3.64 0 0 0-3.619-3.646h-4.98c-6.358 0-11.382-5.035-11.382-11.483V4.136c0-6.449 5.025-11.483 11.383-11.483v-.009Zm15.564 21.27h-.01a3.578 3.578 0 0 0-2.575 1.061 3.615 3.615 0 0 0-1.061 2.576c0 .962.39 1.887 1.07 2.567a3.662 3.662 0 0 0 2.567 1.053h63.717a3.633 3.633 0 0 0 3.61-3.62 3.587 3.587 0 0 0-1.052-2.566 3.618 3.618 0 0 0-2.558-1.07H81.067Zm0 26.431h-.01a3.586 3.586 0 0 0-2.566 1.052 3.636 3.636 0 0 0-.01 5.143 3.653 3.653 0 0 0 2.577 1.062h25.66c.961 0 1.887-.39 2.558-1.07a3.67 3.67 0 0 0 1.061-2.577 3.62 3.62 0 0 0-3.619-3.61H81.067Zm62.775.89h94.801c6.358 0 11.41 5.06 11.41 11.518v53.215c0 6.449-5.052 11.519-11.41 11.519h-4.952v-.009a3.627 3.627 0 0 0-3.646 3.61v21.134l-23.51-23.673a3.611 3.611 0 0 0-2.576-1.07H143.84c-6.358 0-11.383-5.061-11.383-11.519V52.745c0-6.449 5.025-11.52 11.383-11.52l.002.01Zm64.416 23.59-.009-.009a3.634 3.634 0 0 0-2.604.962l-20.779 19.228-7.71-9.133v.009a3.663 3.663 0 0 0-2.467-1.28 3.652 3.652 0 0 0-3.927 3.32 3.642 3.642 0 0 0 .843 2.649l10.186 12.027a3.631 3.631 0 0 0 5.224.318l23.564-21.796h-.009a3.614 3.614 0 0 0 .19-5.143 3.595 3.595 0 0 0-2.512-1.16l.01.008Z"
        fill="#FF6D1B"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h280v144H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FeedbackHomeIcon;
